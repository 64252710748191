import React, { useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';
import Loader from './components/Loader';
import ProtectedRoute from './components/ProtectedRoute';
import { initGA, logPageView } from './analytics';

// Lazy load components
const Navbar = React.lazy(() => import('./components/Navbar'));
const Hero = React.lazy(() => import('./components/Hero'));
const ValueProps = React.lazy(() => import('./components/ValueProps'));
const About = React.lazy(() => import('./components/About'));
const Contact = React.lazy(() => import('./components/Contact'));
const ComingSoonPricing = React.lazy(() => import('./components/ComingSoonPricing'));
const Login = React.lazy(() => import('./components/Login'));
const Registration = React.lazy(() => import('./components/Registration'));
const OrganizationDetails = React.lazy(() => import('./components/OrganizationDetails'));
const Profile = React.lazy(() => import('./components/Profile'));
const Dashboard = React.lazy(() => import('./components/UserDashboard'));
const AdminDashboard = React.lazy(() => import('./components/AdminDashboard'));
const VerifyEmail = React.lazy(() => import('./components/VerifyEmail'));
const CheckEmail = React.lazy(() => import('./components/CheckEmail'));
const NotFound = React.lazy(() => import('./components/NotFound'));

const PageViewTracker = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname);
    }, [location]);

    return null;
};

function App() {
    useEffect(() => {
        initGA();
    }, []);

    return (
        <Router>
            <PageViewTracker />
            <div className="bg-gray-900 min-h-screen text-white">
                <a
                    href="#main-content"
                    className="sr-only focus:not-sr-only absolute top-4 left-4 bg-white text-black font-semibold py-2 px-4 rounded"
                >
                    Skip to Content
                </a>

                <Suspense fallback={<Loader />}>
                    <Navbar />
                </Suspense>

                <main id="main-content" role="main" className="pt-16">
                    <Routes>
                        <Route path="/" element={
                            <>
                                {/* Hero Section with adjusted height to account for the navbar */}
                                <div style={{ height: 'calc(100vh - 4rem)' }}>
                                    <Suspense fallback={<Loader />}>
                                        <Hero />
                                    </Suspense>
                                </div>
                                <Suspense fallback={<Loader />}>
                                    <ValueProps />
                                </Suspense>
                                <Suspense fallback={<Loader />}>
                                    <ComingSoonPricing />
                                </Suspense>
                                <Suspense fallback={<Loader />}>
                                    <About />
                                </Suspense>
                                <Suspense fallback={<Loader />}>
                                    <Contact />
                                </Suspense>
                            </>
                        } />

                        {/* Login and Registration routes */}
                        <Route path="/login" element={
                            <Suspense fallback={<Loader />}>
                                <Login />
                            </Suspense>
                        } />
                        <Route path="/register" element={
                            <Suspense fallback={<Loader />}>
                                <Registration />
                            </Suspense>
                        } />
                        <Route path="/register/organization-details" element={
                            <Suspense fallback={<Loader />}>
                                <OrganizationDetails />
                            </Suspense>
                        } />

                        {/* Protected Profile route */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/profile" element={
                                <Suspense fallback={<Loader />}>
                                    <Profile />
                                </Suspense>
                            } />
                        </Route>

                        {/* Protected User Dashboard route */}
                        <Route element={<ProtectedRoute />}>
                            <Route path="/dashboard" element={
                                <Suspense fallback={<Loader />}>
                                    <Dashboard />
                                </Suspense>
                            } />
                        </Route>

                        {/* Protected Admin Dashboard route */}
                        <Route element={<ProtectedRoute requiredRole="siteAdmin" />}>
                            <Route path="/admin-dashboard" element={
                                <Suspense fallback={<Loader />}>
                                    <AdminDashboard />
                                </Suspense>
                            } />
                        </Route>

                        {/* Email Verification Routes */}
                        <Route path="/verify-email" element={
                            <Suspense fallback={<Loader />}>
                                <VerifyEmail />
                            </Suspense>
                        } />
                        <Route path="/check-email" element={
                            <Suspense fallback={<Loader />}>
                                <CheckEmail />
                            </Suspense>
                        } />

                        {/* 404 Not Found route */}
                        <Route path="*" element={
                            <Suspense fallback={<Loader />}>
                                <NotFound />
                            </Suspense>
                        } />
                    </Routes>
                </main>

                <Footer />
                <BackToTopButton />
            </div>
        </Router>
    );
}

export default App;
