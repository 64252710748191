// src/analytics.js
import ReactGA from 'react-ga4';

// Initialize Google Analytics
export const initGA = () => {
    ReactGA.initialize("G-CJ7TYVYQ11"); // Replace with your Tracking ID
};

// Track a page view
export const logPageView = (page) => {
    ReactGA.send({ hitType: "pageview", page });
};
