// src/components/Footer.js
import { motion } from 'framer-motion';

function Footer() {
    return (
        <motion.footer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className="bg-gray-800 py-6 text-center text-gray-400"
            style={{ minHeight: '15vh' }} // Adding minimum height for consistency
        >
            <div className="max-w-4xl mx-auto flex flex-col items-center space-y-4">
                <p className="text-sm">
                    &copy; {new Date().getFullYear()} CompetiTrend. All rights reserved.
                </p>
                <div className="space-x-4">
                    <a href="#about" className="hover:text-white transition-colors" aria-label="About CompetiTrend">About</a>
                    <a href="#contact" className="hover:text-white transition-colors" aria-label="Contact CompetiTrend">Contact</a>
                </div>
            </div>
        </motion.footer>
    );
}

export default Footer;
