// src/components/BackToTopButton.js
import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';
import { motion } from 'framer-motion';

function BackToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when the user scrolls down
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.pageYOffset > 300) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <div>
            {isVisible && (
                <motion.button
                    onClick={scrollToTop}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.6, ease: [0.42, 0, 0.58, 1] }} // Smooth "ease-in-out" transition
                    className="fixed bottom-10 right-10 p-3 rounded-full transition-all transform hover:scale-105"
                    aria-label="Scroll to top"
                    style={{
                        background: "linear-gradient(to right, #FF7F00, #FF9900, #FF4500)",
                        color: "white",
                        textShadow: "1px 1px 3px rgba(0, 0, 0, 0.5)", // Only text shadow for the arrow
                    }}
                >
                    <FaArrowUp />
                </motion.button>
            )}
        </div>
    );
}

export default BackToTopButton;
