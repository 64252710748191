import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [token, setToken] = useState(() => localStorage.getItem('token'));
    const [userData, setUserData] = useState(() => {
        const storedToken = localStorage.getItem('token');
        return storedToken ? jwtDecode(storedToken) : null;
    });

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (token) {
                try {
                    const decoded = jwtDecode(token);

                    if (decoded.exp * 1000 < Date.now()) {
                        logoutUser();
                    } else {
                        const timeoutId = setTimeout(() => {
                            logoutUser();
                        }, decoded.exp * 1000 - Date.now());

                        return () => clearTimeout(timeoutId);
                    }
                } catch (error) {
                    console.error("Invalid token:", error);
                    logoutUser();
                }
            }
        };

        checkTokenExpiration();
    }, [token]);

    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'token') {
                const newToken = event.newValue;

                if (!newToken) {
                    logoutUser();
                } else {
                    try {
                        const decoded = jwtDecode(newToken);
                        setToken(newToken);
                        setUserData(decoded);
                    } catch (error) {
                        console.error("Invalid token in local storage:", error);
                        logoutUser();
                    }
                }
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    const loginUser = (receivedToken) => {
        setToken(receivedToken);
        localStorage.setItem('token', receivedToken);
        setUserData(jwtDecode(receivedToken));
    };

    const logoutUser = () => {
        setToken(null);
        setUserData(null);
        localStorage.removeItem('token');
    };

    const refreshUserData = () => {
        const storedToken = localStorage.getItem('token');
        if (storedToken) {
            setUserData(jwtDecode(storedToken));
        }
    };

    return (
        <UserContext.Provider value={{ token, userData, loginUser, logoutUser, refreshUserData }}>
            {children}
        </UserContext.Provider>
    );
};
