// src/components/Loader.js
import React from 'react';

function Loader() {
    return (
        <div className="flex items-center justify-center min-h-screen" style={{ minHeight: '100vh' }}>
            <div className="loader-spinner" style={{ height: '100px', width: '100px' }}>Loading...</div>
        </div>
    );
}

export default Loader;
