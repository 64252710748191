import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { UserContext } from '../contexts/UserContext';

const ProtectedRoute = ({ requiredRole }) => {
    const { token, userData } = useContext(UserContext);

    if (!token) {
        return <Navigate to="/login" replace />;
    }

    if (!userData?.isVerified) {
        return <Navigate to="/check-email" replace />;
    }

    if (requiredRole && userData.accessLevel !== requiredRole) {
        return <Navigate to="/dashboard" replace />;
    }

    return <Outlet />;
};

export default ProtectedRoute;
